const contactPoints = [
  // Real numbers with CCP format value
  "+12705243512", 
 /* "+17154233300",
  "+16164514196",
  "+14106412193",
  "+14107688890",
  "+17063277361",
  "+16028708787",
  "+13522376430",
  "+18655396322",
  "+19198628602",*/
];

export default contactPoints;
