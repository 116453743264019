import React, { Component } from "react";

import "amazon-connect-streams";
import "amazon-connect-chatjs";
// Amplify
import {Auth} from "aws-amplify";
import "./CCP.css";
import moment from "moment";
import LayoutVariables from "variables/layout";
// Redux
import { connect as redux_connect }  from "react-redux";
import {host} from "../../variables/apis";
import managers from "variables/managers";

class CCP extends Component {
  constructor(props) {
    super(props);
    this.containerDiv = React.createRef();
  }

  async handleGetManagerAccess(username){
    if (managers.includes(username)){
      this.props.onChangeState("manager_access",true);
      return
    }
    const Init = {
      headers : { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
    let url = `${host}manager-access?user=`;
    fetch(url+username, Init)
    .then(response => response.json())
    .then(res =>{
      //console.log("Manager Access",res);
      this.props.onChangeState("manager_access",res);
    });
  }

  componentDidMount() {
    /* global connect */
    connect.core.initCCP(this.containerDiv.current, {
      ccpUrl: LayoutVariables.CCP_URL,
      loginPopup: false,
      loginPopupAutoClose: true,
      region: 'us-east-1',
      softphone: {
        allowFramedSoftphone: true,
      },
    });


    connect.agent((agent) => {
      // setAgentName(agent.getName())//Get Agent Name
      setInterval(() => {
        this.props.handleStateDuration(
          "00:" + moment(agent.getStateDuration()).format("mm:ss")
        );
      }, 1000);

      //console.log("AGENT CONFIGURATION", agent.getConfiguration());
      //console.log("Agent Routing Profile",agent.getRoutingProfile());
      var config = agent.getConfiguration();
      //var username = config.username;
      //this.props.handleGetAgentProfile(config);
      //this.handleGetManagerAccess("Alaganp"); // Test value for manager username
      this.handleGetManagerAccess(config.username);
      this.props.onChangeState("ccpAgentProfile",config);

      agent.onRefresh((agent) => {
        ////console.log(agent);
        var permissions = agent.getRoutingProfile();

        ////console.log(permissions);
        //this.props.notify("permisions", "success");
      });
      agent.onStateChange((a) => {
        //{agent: Agent, oldState: "Offline", newState: "Busy"}
        ////console.log("onStateChange", a.newState);
        // console.log(contactObj.getState());
        //console.log(agent.getAgentStates());
        switch (a.newState) {
          case "Break":
            this.props.notify(
              "You are in a break! Enjoy it!",
              "warning",
              "fa fa-coffee",
              "tc"
            );
            break;
          case "Lunch":
            this.props.notify(
              "You are in your lunch! Enjoy your food!",
              "warning",
              "fa fa-cutlery",
              "tc"
            );
            break;
          case "Offline":
            this.props.notify(
              "You are now offline!",
              "danger",
              "nc-icon nc-time-alarm",
              "tc"
            );
            break;
          case "Available":
            this.props.notify(
              "You are available!",
              "info",
              "fa fa-user-circle-o",
              "tc"
            );
            this.props.handleResetInfo();
            break;
          case "AfterCallWork":
            this.props.notify(
              "After call work needs to be done!",
              "primary",
              "nc-icon nc-settings",
              "tc"
            );
            //this.props.handleResetInfo();
            break;
        }
        this.props.handleNewAgentState(a.newState);
        // setAgentStatus(a.newState);
      });
      agent.onRoutable((a) => {
        //this.props.handleQueue(this.props.ccpAgentProfile.routingProfile.name);
        //Agent on Ready
        //console.log("onRoutable", a);
        //this.props.notify("onRoutable", "success");
      });
      agent.onNotRoutable((a) => {
        //this.props.handleQueue("");
        //Agent on Not Ready
        //console.log("onNotRoutable", a);
        //this.props.notify("onNotRoutable", "success");
      });
      agent.onOffline((a) => {
        //this.props.handleQueue("");
        //Agent on Offline
        //console.log("onOffline", a);
        //this.props.notify("onOffline", "success");
      });
      agent.onError((a) => {
        //Agent on Error receiving agent state
        //console.log("onError", a);
        //this.props.notify("onError", "success");
      });
      agent.onSoftphoneError((a) => {
        //Agent unable to open softphone connection
        //console.log("onSoftphoneError", a);
        //this.props.notify("onSoftphoneError", "success");
      });
      agent.onAfterCallWork((a) => {
        //Agent ACW status
        //console.log("onAfterCallWork", a);
        //this.props.notify("onAfterCallWork", "success");
      });
      agent.onContactPending((a) => {
        //Agent Has Pending Contact
        //console.log("onContactPending", a);
        //this.props.notify("onContactPending", "success");
      });
    });
    let contactObj = connect.contact((contact) => {
      contact.onRefresh((c) => {
        //Agent Has Pending Contact
        //console.log("onRefresh", c);
        //this.props.notify("onRefresh", "success");
      });
      contact.onIncoming((c) => {
        //Agent Has Pending Contact
        //console.log("onIncoming", c);
        //this.props.notify("onIncoming!", "primary");
        this.props.handlerOpen();
      });
      contact.onPending((c) => {
        //Agent Has Pending Contact
        //console.log("onPending", c);
        this.props.notify("Call on pending", "success", "fa fa-phone", "tc");
      });
      contact.onConnecting((c) => {
        let connectionType = c.getType();
        //Agent Has Pending Contact
        //console.log("onConnecting", c);
        this.props.notify(
          "Call incoming!",
          "primary",
          "fa fa-volume-control-phone",
          "tc"
        );
        this.props.openHandler();
        this.props.shakeHandler(true);
        this.props.handleNewAgentState("Connecting");
        this.props.handleQueue(
          contact !== null ? contact.getQueue().name : null
        );
        var conns = contact.getConnections();
        conns.map((connection) => {
          var endpoint = connection.getEndpoint();
          if (connectionType === "voice" && endpoint.phoneNumber) {
            this.props.handleFetchCallData(endpoint.phoneNumber);
            this.props.onChangeState("phoneNumber",endpoint.phoneNumber);
          } else if (connectionType === "chat") {
            let attributes = contact.getAttributes();
            let email = attributes.customerName.value;
            if (email) {
              this.props.handleFetchCallData(email);
            }
          }
        });
      });
      contact.onAccepted((c) => {
        //Agent Has Pending Contact
        //console.log("onAccepted", c);
        //console.log("onAccepted", contact);
        //console.log(contact.toSnapshot());
        this.props.shakeHandler(false);
        //this.props.notify("onAccepted", "success");
        //contactData
        //connections
        //
      });
      contact.onMissed((c) => {
        //Agent Has Pending Contact
        //console.log("onMissed", c);
        this.props.notify("Missed a call", "danger", "fa fa-phone", "tc");
        this.props.shakeHandler(false);
      });
      contact.onEnded((c) => {
        this.props.handleQueue("");
        //this.props.handleResetInfo();
        //Agent Has Pending Contact
        //console.log("onEnded", c);
      });
      contact.onACW((c) => {
        //Agent Has Pending Contact
        //console.log("onACW", c);
        var conns = contact.getConnections();
        conns.map((connection) => {
          var endpoint = connection.getEndpoint();
          if (endpoint.type === "phone_number") {
            this.props.handleFetchCallData(endpoint.phoneNumber);
            this.props.onChangeState("phoneNumber",endpoint.phoneNumber);
          }

        });
      });
      contact.onConnected((c) => {
        let connectionType = c.getType();
        this.props.notify("You are on a call", "info", "fa fa-phone", "tc");
        this.props.handleNewAgentState("On call");
        this.props.shakeHandler(false);
        this.props.handleQueue(
          contact !== null ? contact.getQueue().name : null
        );

        var conns = contact.getConnections();
        conns.map((connection) => {
          var endpoint = connection.getEndpoint();
          if (connectionType === "voice" && endpoint.phoneNumber) {
            this.props.handleFetchCallData(endpoint.phoneNumber);
            this.props.onChangeState("phoneNumber",endpoint.phoneNumber);
          } else if (connectionType === "chat") {
            let attributes = contact.getAttributes();
            let email = attributes.customerName.value;
            if (email) {
              this.props.handleFetchCallData(email);
            }
          }
        });
        //Agent Has Pending Contact
        this.props.notify("onConnected", "success");
      });
    });
  }

  
  render() {
    //console.log("CCP props:", this.props);
    return (
      <div>
        <div className="containerDiv" ref={this.containerDiv} />
      </div>
    );
  }
}

// ---- REDUX STUFF ---- //
const mapStateToProps = state => {
  return {
      phoneNumber : state.phoneNumber,
      ccpAgentProfile : state.ccpAgentProfile,
      manager_access : state.manager_access,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onChangeState: (type, value) => dispatch(updateState(type, value)),
  }
}

const updateState = (type, value) => dispatch => {
  dispatch({
      type: type,
      value,
  });
  return Promise.resolve();
}
// ENDS REDUX STUFF //


export default redux_connect(mapStateToProps, mapDispatchToProps)(CCP);
